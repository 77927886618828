import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BREAKPOINTS, COLORS } from "../ui/variables";
import Heading2 from "../ui/Heading2";
import Heading3 from "../ui/Heading3";
import ParagraphSmall from "../ui/ParagraphSmall";
import { graphql } from "gatsby";
import { AuthorsPageQuery } from "../../graphql-types";

const Header = styled.header`
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding: 40px 0;

  @media ${BREAKPOINTS.MOBILE} {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Grouping = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
`;

const Avatar = styled(Img)`
  max-width: 200px;
  max-height: 200px;
  border-radius: 100px;
  margin-bottom: 12px;

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 120px;
    max-height: 120px;
    margin: auto;
    margin-bottom: 12px;
  }
`;

const Authors = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-wrap: wrap;
  justify-content: space-around;

  @media ${BREAKPOINTS.TABLET} {
    justify-content: space-between;
  }

  @media ${BREAKPOINTS.MOBILE} {
    justify-content: center;
  }
`;

const Author = styled.li`
  width: calc(100% / 3);
  flex-shrink: 0;
  margin-bottom: 60px;
  max-width: 400px;
  padding: 0 22px;

  @media ${BREAKPOINTS.TABLET} {
    width: 48%;
    max-width: inherit;
  }

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
    max-width: 400px;
    padding: 0;
  }
`;

const AuthorTitle = styled.strong`
  margin-bottom: 16px;
  display: inline-block;
`;

const AuthorBio = styled(ParagraphSmall)`
  text-align: left;
  margin: auto;
`;

const GroupHeading = styled(Heading3)`
  color: ${COLORS.ORANGE};
  margin-bottom: 40px;
`;

export const pageQuery = graphql`
  query AuthorsPage {
    jamieAvatar: file(relativePath: { eq: "pages/authors/jamie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    erinAvatar: file(relativePath: { eq: "pages/authors/erin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    michelleAvatar: file(relativePath: { eq: "pages/authors/michelle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AuthorPage = ({ data }: { data: AuthorsPageQuery }) => {
  return (
    <Layout>
      <SEO
        title="Small Door's Medical Experts &amp; Authors"
        description="Small Door Veterinary's medical experts and authors help create high-quality, veterinarian recommended, and research based articles."
      />
      <Header>
        <div
          css={css`
            color: ${COLORS.ORANGE};
            text-transform: uppercase;
            margin-bottom: 14px;
          `}
        >
          Meet our medical experts
        </div>
        <Heading2>
          All of Small Door's Learning Center articles are published by our team
          of esteemed veterinarians.
        </Heading2>
      </Header>

      <Grouping>
        <GroupHeading>Our Medical Experts</GroupHeading>
        <Authors>
          <Author>
            <Avatar fluid={data.jamieAvatar.childImageSharp.fluid} />
            <AuthorTitle>Dr. Jamie Richardson, BVetMed</AuthorTitle>
            <AuthorBio>
              Small Door's Medical Chief of Staff, Dr. Richardson has worked in
              veterinary medicine across the world for over 10 years. She holds
              a bachelor's degree in Biology and Chemistry and a degree in
              Veterinary Medicine from London's prestigious Royal Veterinary
              College. She has particular interests in preventative care,
              dentistry, and internal medicine; particularly endocrine and
              metabolic disease.
            </AuthorBio>
          </Author>
          <Author>
            <Avatar fluid={data.michelleAvatar.childImageSharp.fluid} />
            <AuthorTitle>Dr. Michelle Bourjaily, DVM</AuthorTitle>
            <AuthorBio>
              Dr. Bourjaily holds a bachelor's degree in Food Science and Human
              Nutrition and a degree in Veterinary Medicine, from which she
              graduated Magna Cum Laude. Practicing for over 8 years, she has
              extensive experience in complex case management, and takes a
              special interest in internal medicine, soft tissue surgery,
              ophthalmology and behavioral medicine.
            </AuthorBio>
          </Author>
          <Author>
            <Avatar fluid={data.erinAvatar.childImageSharp.fluid} />
            <AuthorTitle>Dr. Erin Keefe, DVM</AuthorTitle>
            <AuthorBio>
              Dr. Keefe holds a bachelor's degree in Biology, a master's degree
              in Molecular Biology, and a degree in Veterinary Medicine. A
              member of the New York State Veterinary Medical Society and the
              American Veterinary Medical Association, she has been practicing
              for over 10 years and holds a special interest in preventative
              care, internal medicine, and shelter medicine.
            </AuthorBio>
          </Author>
        </Authors>
      </Grouping>
    </Layout>
  );
};

export default AuthorPage;
